exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-fibra-jsx": () => import("./../../../src/pages/fibra.jsx" /* webpackChunkName: "component---src-pages-fibra-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pontiradio-jsx": () => import("./../../../src/pages/pontiradio.jsx" /* webpackChunkName: "component---src-pages-pontiradio-jsx" */),
  "component---src-pages-videosorveglianza-jsx": () => import("./../../../src/pages/videosorveglianza.jsx" /* webpackChunkName: "component---src-pages-videosorveglianza-jsx" */)
}

